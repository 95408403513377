import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {

    const transition = { type: 'spring', duration: 3 }
    const mobile = window.innerWidth<=768 ? true: false;
    return (
        <div className="hero">
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best ad*/}
                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile? '165px': '300px' }}
                        whileInView={{ left: '9px' }}
                        transition={{ ...transition, type: 'tween' }}
                    ></motion.div>
                    <span>F the FitClub a melhor academia da região</span>
                </div>

                {/* Hero Heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Modele </span>
                        <span>Seu </span>
                    </div>
                    <div>
                        <span>Corpo Ideal</span>
                    </div>
                    <div>
                        <span>
                            Aqui te ajudamos a modelar o seu corpo com saúde.
                        </span>
                    </div>
                </div>

                {/* figures */}
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter 
                            end={50} 
                            start={0} 
                            delay='2' 
                            preFix="+"/>
                        </span>
                        <span>Especialistas</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter 
                            end={100} 
                            start={50} 
                            delay='4' 
                            preFix="+"/>
                        </span>
                        <span>Membros</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter 
                            end={50} 
                            start={0} 
                            delay='2' 
                            preFix="+"/>
                        </span>
                        <span>Programas Fitness</span>
                    </div>
                </div>
                {/* hero buttons */}
                <div className="hero-buttons">
                    <buttons className="btn">Juntar-se</buttons>
                    <buttons className="btn">Saiba Mais</buttons>
                </div>
            </div>
            <div className="right-h">
                <button className="btn">Entrar</button>

                <motion.div

                    initial={{ right: "-1rem" }}
                    whileInView={{ right: "4rem" }}
                    transition={transition}

                    className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* hero images */}
                <img src={hero_image} alt="" className="hero-image" />
                <motion.img

                    initial={{ right: "11rem" }}
                    whileInView={{ right: "20rem" }}
                    transition={transition}

                    src={hero_image_back} alt="" className="hero-image-back" />

                {/* calories */}
                <motion.div

                    initial={{ right: "37rem" }}
                    whileInView={{ right: "28rem" }}
                    transition={transition}
                    className="calories">

                    <img src={Calories} alt="" />
                    <div>
                        <span>Calorias Queimadas</span>
                        <span>220 Kcal</span>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}

export default Hero