import React from 'react';
import './Plans.css';
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png';

const Plans = () => {
    return (
        <div className="plans-container">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="programs-header" style={{ gap: '2rem' }}>
                <span className="stroke-text">INICIE A</span>
                <span>SUA JORNADA</span>
                <span className="stroke-text">CONOSCO </span>
            </div>

            {/* PLANS */}
            <div className="plans">
                {plansData.map((plan, index) => (
                    <div className="plan" key={index}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>{plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature, featureIndex) => (
                                <div className="feature" key={featureIndex}>
                                    <img src={whiteTick} alt="" />
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span>Olhe mais beneficios -> </span>
                        </div>
                        <button className="btn">Escolher</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Plans;
