import React from 'react'
import './Reasons.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import tick from '../../assets/tick.png'

const Reasons = () => {
    return (
        <div className="Reasons" id='reasons'>
            <div className="left-r">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image4} alt="" />
            </div>
            <div className="right-r">
                <span>algumas razões</span>

                <div>
                    <span className="stroke-text">porque </span>
                    <span>nos escolher?</span>
                </div>
                <div className="details-r">
                    <div>
                        <img src={tick} alt=""></img>
                        <span>MAIS DE 100 ESPECIALISTAS</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>TREINE DE FORMA INTELIGENTE</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>1 MES GRATIS PARA NOVO MEMBRO</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>PARCEIRO DE CONFIANÇA</span>
                    </div>
                </div>
                <br/>
                <span style={{
                    color: "var(--gray)",
                    fontWeight: "normal",
                }}>  PARCEIROS</span>
                <br/>
                <div className="partners">
                    <img src={nb} alt="" />
                    <img src={adidas} alt="" />
                    <img src={nike} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Reasons