import React, { useRef } from 'react';
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
    
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_egq30ju', 'template_g4yknu8', form.current, 'DdeAxMW8HitlQK-0I')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <div className="Join" id="join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className="stroke-text">COMECE AGORA</span>
                    <span>EVOLUA</span>
                </div>
                <div>
                    <span>O SEU CORPO</span>
                    <span className="stroke-text">CONOSCO</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className="email-container" onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder="Seu Email aqui" />
                    <button className="btn btn-j">Assinar</button>
                </form>
            </div>
        </div>
    )
}

export default Join